import * as React from "react"

import Layout from "../layouts/layout"
import Search from "../modules/Search/Search"

const IndexPage = () => (
  <Layout title="Strona główna">
    <Search />
  </Layout>
)

export default IndexPage
