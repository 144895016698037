import * as React from "react"

import Header from "../modules/Header/Header"
import Footer from "../modules/Footer/Footer"
import Aside from "../modules/Aside/Aside"
import Seo from "../components/Seo"
import CheckApi from "../components/ChceckAPI/CheckApi"

import "../styles/index.scss"

const Layout = ({ children, title }) => {
  return (
    <div className="layout">
      <Seo title={title} />
      <CheckApi />
      <Header />
      <main className="main-content">{children}</main>
      <Aside />
      <Footer />
    </div>
  )
}

export default Layout
